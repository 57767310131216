<template>
	<div class="page-content no-padding">
        <div class="grid">
            <div class="col-12">
                <Toolbar class="p-mb-4" v-if="userCanCreate || userCanDelete">
                    <template #start>
                        <Button label="Subir" icon="pi pi-plus" class="p-button-success mr-2" @click="showCreateDocumentDialog" v-if="userCanCreate" />
                        <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger mr-2" @click="confirmDeleteSelected" :disabled="!selectedDocuments || !selectedDocuments.length" v-if="userCanDelete" />
                    </template>
                    <template #end>
                    </template>
                </Toolbar>
                <DataTable :value="documents" v-model:selection="selectedDocuments" v-model:expandedRows="expandedRows" :lazy="true" :paginator="true" :totalRecords="totalRecords" class="p-datatable-gridlines" :rows="15" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll" @page="pageChanged" @sort="sortChanged" @rowSelect="rowSelect" :rowClass="rowClass" :sortField="orderBy" :sortOrder="sortOrder" removableSort>
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">Documentos</h5>
                            <div class="flex flex-column md:flex-row">
                                <MultiSelect v-model="selectedCategories" :options="allCategories" optionLabel="name" placeholder="Filtrar categoría" :maxSelectedLabels="2" selectedItemsLabel="{0} seleccionada(s)" :filter="true" dataKey="id" @change="fetchDocuments" class="mb-2 mr-0 md:mb-0 md:mr-2" />
                                <ToggleButton v-model="withTrashed" onLabel="Sin eliminados" offLabel="Ver eliminados" onIcon="pi pi-eye-slash" offIcon="pi pi-eye" @change="fetchDocuments" class="mb-2 mr-0 md:mb-0 md:mr-2" v-if="userCanRestore" />
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="searchQuery" placeholder="Buscar..." />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No hay documentos.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column selectionMode="multiple" style="width: 3rem"></Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column header="Título" field="title" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            <div class="flex flex-row">
                                <iconify-icon v-if="data.extra_data && data.extra_data.pinned" icon="mdi:pin" class="mr-2" style="font-size: 1.8em;" v-tooltip.right="{value: 'Mostrado en inicio'}"></iconify-icon>
                                <iconify-icon :icon="documentService.getDocumentIcon(data.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                {{data.title}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$appState.texts.category" field="category" style="min-width:12rem" :sortable="false">
                        <template #body="{data}">
                            {{data.category.name}}
                        </template>
                    </Column>
                    <Column header="Creado el" field="created_at" dataType="created_at" style="min-width:10rem" :sortable="true">
                        <template #body="{data}">
                            {{formatDate(data.created_at)}}
                        </template>
                    </Column>
                    <Column :exportable="false" style="min-width:8rem">
                        <template #body="{data}">
                            <Button v-if="userCanUpdate && !data.deleted_at" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="showUpdateDocumentDialog(data.id)" />
                            <Button v-if="userCanDelete && !data.deleted_at" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteDocument(data)" />
                            <Button v-if="userCanRestore && data.deleted_at" icon="pi pi-undo" class="p-button-rounded p-button-success" @click="confirmRestoreDocument(data)" />
                        </template>
                    </Column>
                    <template #expansion="{data}">
                        <div style="padding-left: 6rem;">
                            <p>{{data.description}}</p>
                            <p>
                                <a v-if="!isPdf(data.mime_type) || (isPdf(data.mime_type) && canDownload(data.extra_data))" :href="downloadUrl(data.id)" target="_blank" class="p-button mr-3"><iconify-icon icon="mdi:download" class="mr-2" style="font-size: 1.8em;"></iconify-icon> Descargar documento</a>
                                <a v-if="isPdf(data.mime_type)" :href="viewPdfUrl(data.id)" target="_blank" class="p-button"><iconify-icon :icon="documentService.getDocumentIcon(data.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon> Ver documento</a>
                            </p>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="deleteDocumentDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="document">¿Quieres eliminar el documento <b>{{document.title}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDocumentDialog = false"/>
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteDocument" />
        </template>
    </Dialog>

    <Dialog v-model:visible="deleteDocumentsDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="documents">¿Quieres eliminar los documentos seleccionados?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDocumentsDialog = false"/>
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteSelectedDocuments" />
        </template>
    </Dialog>

    <Dialog v-model:visible="restoreDocumentDialog" :style="{width: '450px'}" header="Confirmar restauración" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="document">¿Quieres restaurar el documento <b>{{document.title}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="restoreDocumentDialog = false"/>
            <Button label="Sí" icon="pi pi-undo" class="p-button-text p-button-success" @click="restoreDocument" />
        </template>
    </Dialog>

    <Dialog v-model:visible="updateDocumentDialog" :style="{width: '450px'}" header="Editar documento" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="title">Título</label>
                <InputText v-model="v$.updateDocument.title.$model" id="title" type="text" class="w-full mb-2" :class="{'p-invalid': v$.updateDocument.title.$error}" required />
                <div v-for="error of v$.updateDocument.title.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="description">Descripción</label>
                <InputText v-model="updateDocument.description" id="description" type="description" class="w-full mb-2" />
            </div>
            <div class="field mb-4">
                <label for="role">{{ $appState.texts.category }}</label>
                <TreeSelect v-model="v$.updateDocument.category.$model" :options="categories" class="w-full mb-2" :class="{'p-invalid': v$.updateDocument.category.$error}" required />
                <div v-for="error of v$.updateDocument.category.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <FileUpload name="document[]" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Reemplazar archivo" :accept="$appState.acceptedMimetypes" :fileLimit="1" />
            <div class="field mt-4">
                <InputSwitch v-model="updateDocument.disallowDownload" id="disallowDownload" />
                <label for="disallowDownload" class="ml-3">Impedir la descarga del documento (solo para PDF)</label>
            </div>
            <div class="field mt-4">
                <InputSwitch v-model="updateDocument.pinned" id="pinned" />
                <label for="pinned" class="ml-3">Mostrar en inicio</label>
            </div>

            <div class="mt-4" v-if="updateError"><small class="p-error font-bold">{{ updateError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="updateDocumentDialog = false" :disabled="working" />
            <Button label="Guardar" icon="pi pi-save" :loading="working" class="p-button-text p-button-success" @click="saveDocument" :disabled="canUpdateDocument || working" />
        </template>
    </Dialog>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import ApiService from '@/service/ApiService.js';
import AuthService from '@/service/AuthService.js';
import CategoryService from '@/service/CategoryService.js';
import DocumentService from '@/service/DocumentService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';

const compUrl = '/documents';

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            documents: null,
            page: 1,
            totalRecords: 0,
            orderBy: 'created_at',
            sortOrder: -1,
            withTrashed: false,
            loading: true,
            selectedDocuments: [],
            expandedRows: [],
            deleteDocumentDialog: false,
            deleteDocumentsDialog: false,
            restoreDocumentDialog: false,
            updateDocumentDialog: false,
            document: null,
            selectedCategories: [],
            allCategories: null,
            categories: null,
            query: '',
            qTimeout: null,
            validationMessages: {},
            working: false,
            updateDocument: {
                title: '',
                description: '',
                category: '',
                file: '',
                disallowDownload: false,
                pinned: false,
            },
            updateError: null,
        }
    },
    authService: null,
    categoryService: null,
    documentService: null,
    errorReportService: null,
    selectedFile: null,
    created() {
        this.authService = new AuthService();
        this.categoryService = new CategoryService();
        this.documentService = new DocumentService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    validations() {
        return {
            updateDocument: {
                title: { required },
                category: { required },
                disallowDownload: { required },
                pinned: { required },
            }
        };
    },
    emits: ['updateBreadcrumbs', 'newDocument'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: 'Documentos', to: compUrl, replace: true});

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchCategories();
                    this.fetchDocuments();
                },
            );
        } else {
            this.fetchCategories();
            this.fetchDocuments();
        }
    },
    computed: {
        searchQuery: {
            get() {
                return this.query;
            },
            set(value) {
                if (this.qTimeout) {
                    clearTimeout(this.qTimeout);
                }
                this.qTimeout = setTimeout(() => {
                    this.query = value;

                    if (this.query.length >= 3 || this.query.length === 0) {
                        this.fetchDocuments();
                    }
                }, 500);
            }
        },
        userCanCreate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:create');
        },
        userCanUpdate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:update');
        },
        userCanDelete() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:delete');
        },
        userCanRestore() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:restore');
        },
        canUpdateDocument() {
            return this.v$.updateDocument.title.$errors.length !== 0
                || this.v$.updateDocument.category.$errors.length !== 0
                || this.updateDocument.title.length === 0
                || (this.updateDocument.category === null || this.updateDocument.category === '');
        },
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        rowClass(data) {
            return data.deleted_at !== null ? 'deleted-row' : null;
        },
        rowSelect(event) {
            if (event.data.deleted_at) {
                this.selectedDocuments = this.selectedDocuments.filter(document => document.id !== event.data.id);
            }
        },
        confirmDeleteSelected() {
            this.deleteDocumentsDialog = true;
        },
        confirmDeleteDocument(document) {
            this.document = document;
            this.deleteDocumentDialog = true;
        },
        confirmRestoreDocument(document) {
            this.document = document;
            this.restoreDocumentDialog = true;
        },
        fetchCategories() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:list')) {
                this.loading = true;
                this.categoryService.fetchCategories(this.$appState.estate.id, false, '').then(response => {
                    this.selectedCategories = this.allCategories = response.data.categories; 

                    this.categoryService.fetchCategoriesTree(this.$appState.estate.id, false, '').then(response => {
                        let ac = [];
                        this.buildTree(ac, response.data.categories[0]);
                        this.categories = ac;
                    }).catch(error => {
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.loading = false;
                        this.$toast.add({severity:'error', summary: 'Error', detail: 'D-01: No se ha podido obtener los documentos', life: 3000});
                    });
                }).catch(error => {
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'D-01: No se ha podido obtener los documentos', life: 3000});
                });
            }
        },
        buildTree(a, p) {
            p.children.forEach(c => {
                let ch = {
                    key: c.id,
                    label: c.name,
                    data: {
                        id: c.id,
                        slug: c.slug,
                        name: c.name,
                        short_desc: c.short_desc,
                        extra_data: c.extra_data,
                        created_at: new Date(c.created_at)
                    },
                    children: []
                };

                if (c.children && c.children.length > 0) {
                    this.buildTree(ch.children, c);
                }

                a.push(ch);
            });
        },
        fetchDocuments() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('document:list')) {
                this.loading = true;
                let categories = this.selectedCategories.map(sc => sc.id);
                this.documentService.fetchDocuments(this.$appState.estate.id, this.page, categories, this.withTrashed, this.query, this.orderBy, this.sortOrder).then(response => {
                    this.totalRecords = response.data.documents.total;
                    this.documents = response.data.documents.data;
                    this.loading = false;
                    this.documents.forEach(document => document.created_at = new Date(document.created_at));
                }).catch(error => {
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'D-02: No se ha podido obtener los documentos', life: 3000});
                });
            } else {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'D-00: Acceso no autorizado', life: 3000});
            }
        },
        pageChanged(event) {
            this.page = event.page + 1;
            this.fetchDocuments();
        },
        sortChanged(event) {
            console.log(event);
            this.orderBy = event.sortField;
            this.sortOrder = event.sortOrder;
            this.fetchDocuments();
        },
        showCreateDocumentDialog() {
            this.$emit('newDocument', () => {
                this.fetchDocuments();
            });
        },
        deleteDocument() {
            this.deleteDocumentDialog = false;
            this.loading = true;
            this.documentService.deleteDocument(this.document.id).then(() => {
                this.document = null;
                this.fetchDocuments();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido eliminar el documento', life: 3000});
            });
        },
        deleteSelectedDocuments() {
            this.deleteDocumentsDialog = false;
            this.loading = true;
            this.documentService.deleteDocuments(this.selectedDocuments.map(document => document.id)).then(() => {
                this.selectedDocuments = [];
                this.fetchDocuments();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido eliminar los documentos', life: 3000});
            });
        },
        restoreDocument() {
            this.restoreDocumentDialog = false;
            this.loading = true;
            this.documentService.restoreDocument(this.document.id).then(() => {
                this.document = null;
                this.fetchDocuments();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido restaurar el documento', life: 3000});
            });
        },
        fileChanged(event) {
            this.selectedFile = event.files[0];
            this.updateDocument.file = this.selectedFile.name;
        },
        showUpdateDocumentDialog(documentId) {
            this.loading = true;

            this.documentService.fetchDocument(documentId).then(response => {
                this.document = response.data.document;
                this.updateDocument.title = this.document.title;
                this.updateDocument.description = this.document.description ?? '';
                this.updateDocument.category = {};
                this.updateDocument.category[this.document.category_id] = true;
                this.updateDocument.file = '';
                this.updateDocument.disallowDownload = false;
                this.updateDocument.pinned = false;

                if (this.document.extra_data && this.document.extra_data.disallow_download) {
                    this.updateDocument.disallowDownload = true;
                }

                if (this.document.extra_data && this.document.extra_data.pinned) {
                    this.updateDocument.pinned = true;
                }

                this.loading = false;
                this.updateDocumentDialog = true;
            }).catch(() => {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'D-05: No se ha podido obtener los datos del documento', life: 3000});
            });
        },
        saveDocument() {
            if (!this.working) {
                this.working = true;
                this.updateError = null;

                if (!this.selectedFile) {
                    this.documentService.updateDocument(
                        this.document.id,
                        this.updateDocument.title,
                        this.updateDocument.description,
                        Object.keys(this.updateDocument.category)[0],
                        this.updateDocument.disallowDownload,
                        this.updateDocument.pinned
                    ).then(response => {
                        if (response.data.document) {
                            this.working = false;
                            this.updateDocumentDialog = false;
                            this.document = null;
                            this.fetchDocuments();
                            this.$appState.refreshHomePage = true;
                        } else {
                            this.updateError = 'ERROR D-07: Ha ocurrido un error al actualizar el usuario';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                title: this.updateDocument.title,
                                description: this.updateDocument.description,
                                category_id: Object.keys(this.updateDocument.category)[0],
                                disallow_download: this.updateDocument.disallowDownload
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.updateError = 'ERROR D-06: Ha ocurrido un error al actualizar el usuario';
                    });
                } else {
                    let fd = new FormData();
                    fd.append('_method', 'put');
                    fd.append('estate_id', this.$appState.estate.id);
                    fd.append('title', this.updateDocument.title);
                    fd.append('description', this.updateDocument.description);
                    fd.append('category_id', Object.keys(this.updateDocument.category)[0]);
                    fd.append('document', this.selectedFile);
                    fd.append('disallow_download', this.updateDocument.disallowDownload ? 1: 0);
                    fd.append('pinned', this.updateDocument.pinned ? 1: 0);

                    this.documentService.updateDocumentWithFile(this.document.id, fd).then(response => {
                        if (response.data.document) {
                            this.working = false;
                            this.updateDocumentDialog = false;
                            this.document = null;
                            this.selectedFile = null;
                            this.fetchDocuments();
                            this.$appState.refreshHomePage = true;
                        } else {
                            this.updateError = 'ERROR D-09: Ha ocurrido un error al crear el documento';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                title: this.updateDocument.title,
                                description: this.updateDocument.description,
                                category_id: Object.keys(this.updateDocument.category)[0],
                                document: this.selectedFile.name,
                                file_size: this.selectedFile.size,
                                disallow_download: this.updateDocument.disallowDownload
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.updateError = 'ERROR D-08: Ha ocurrido un error al crear el documento';
                    });
                }
            }
        },
        downloadUrl(id) {
            return ApiService.getDocumentDownloadUrl(id);
        },
        viewPdfUrl(id) {
            return ApiService.getDocumentPdfUrl(id);
        },
        isPdf(mime_type) {
            return mime_type === 'application/pdf';
        },
        canDownload(documentExtraData) {
            if (documentExtraData && documentExtraData.disallow_download) {
                return false;
            }

            return true;
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            align-items: start;
        }
    }

    ::v-deep(.p-datatable .p-datatable-tbody > tr.deleted-row) {
        background-color: var(--red-50);
    }

	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-true {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-false {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

    @media screen and (max-width: 960px) {
        ::v-deep(.p-toolbar) {
            flex-wrap: wrap;
            
            .p-button {
                margin-bottom: 0.25rem;
            }
        }
    }
</style>
